*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root {
  --primary: #9435f2;
  --white: #ffffff;
  --bg: #f5f5f5;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font-size: 1.6rem;
  background: var(--bg);
}

.container {
  max-width: auto;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.swiper_container {
  height: 52rem;
  padding: 2rem 0;
  position: relative;
}

.swiper-slide {
  width: 37rem;
  height: 42rem;
  position: relative;
}

@media (max-width: 1500px) {
  .swiper_container {
    height: 47rem;
  }
  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }
  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }
}

.swiper-slide img {
  width: 37rem;
  height: 42rem;
  border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide video {
  width: 37rem; 
  height: 42rem; 
  border-radius: 2rem;
  object-fit: cover;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.swiper-pagination {
  position: fixed;
  margin-bottom: 35px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8; /* Adjust as needed to ensure it's above other elements */
}

.swiper-pagination .swiper-pagination-bullet {
  margin: 0 4px; /* Increase the spacing between bullets */
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}

/* Responsive adjustments if necessary */
@media (max-width: 500px) {
  .swiper-pagination .swiper-pagination-bullet {
    margin: 0 5px; /* Adjust the spacing for smaller screens if needed */
  }
}
